<template>
  <el-dialog
    title="添加用户"
    :visible.sync="showDialog"
    width="700px"
    :closeOnClickModal="false"
    @open="initData"
    :before-close="closeFn"
  >
    <el-form
      :model="form"
      ref="ruleForm"
      status-icon
      :rules="rules"
      label-width="100px"
    >
      <el-form-item label="用户名：" prop="username">
        <div class="flex-start">
          <el-input
            v-model.trim="form.username"
            placeholder="请输入用户名"
            clearable
            maxlength="64"
            show-word-limit
            class="inputWidth"
          />
          <span class="font12 " style="line-height:14px;width:180px"
            >大小写字母，数字和下划线的组合，不超过64个字符</span
          >
        </div>
      </el-form-item>
      <el-form-item label="初始密码：">
        <b>新建完毕后，系统自动发送密码至短信</b>
      </el-form-item>
      <el-form-item label="姓名：" prop="nickname">
        <div class="flex-start">
          <el-input
            v-model.trim="form.nickname"
            placeholder="请输入姓名"
            clearable
            class="inputWidth"
            show-word-limit
            maxlength="64"
          />
          <!-- <span class="font12" style="line-height:14px;width:180px"
            >大小写英文字母和汉字和点的组合，不超过64个字符</span
          > -->
        </div>
      </el-form-item>
      <el-form-item label="邮箱：" prop="email">
        <el-input
          v-model.trim="form.email"
          placeholder="请输入邮箱"
          clearable
          class="inputWidth"
          show-word-limit
          maxlength="255"
        />
      </el-form-item>
      <el-form-item label="手机号：" prop="cellphone">
        <el-input
          v-model.trim="form.cellphone"
          placeholder="请输入手机号"
          clearable
          show-word-limit
          maxlength="11"
          class="inputWidth"
        />
        <!-- @change="changeInput($event, 'cellphone')" -->
      </el-form-item>
      <el-form-item label="初始状态：" prop="disabled">
        <el-radio-group v-model="disabled">
          <el-radio label="0">启用</el-radio>
          <el-radio label="1">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="sureFn">确 定</el-button>
      <el-button @click="closeFn"> 取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { if_repeat } from '@/api/userLimit';
export default {
  name: 'addDialog',
  props: {
    showDialog: false
  },
  data() {
    let checkUserName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入用户名'));
      } else {
        let reg = /^[a-zA-Z_0-9]+$/;
        if (!reg.test(value)) {
          callback(new Error('用户名不符合规范'));
        } else {
        }
        callback();
      }
    };
    // let checkNickname = (rule, value, callback) => {
    //   if (value === '') {
    //     callback(new Error('请输入姓名'))
    //   }
    //   // else {
    //   //   let reg = /[a-zA-Z0-9·\u4e00-\u9fa5]+$/
    //   //   console.log(reg.test(value))
    //   //   if (!reg.test(value)) {
    //   //     callback(new Error('姓名不符合规范'))
    //   //   } else {
    //   //   }
    //   //   callback()
    //   // }
    // }
    let checkCellphone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!reg.test(value)) {
          callback(new Error('请输入正确的手机号码'));
        } else {
        }
        callback();
      }
    };
    let checkEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入邮箱'));
      } else {
        let reg = /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/;
        if (!reg.test(value)) {
          callback(new Error('请输入正确的邮箱'));
        } else {
        }
        callback();
      }
    };
    return {
      disabled: '1',
      form: {
        username: '',
        nickname: '',
        email: '',
        cellphone: '',
        disabled: 0,
        avatar: ''
      },
      rules: {
        username: [
          { required: true, validator: checkUserName, trigger: 'blur' }
        ],
        nickname: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        email: [{ required: true, validator: checkEmail, trigger: 'blur' }],
        cellphone: [
          { required: true, validator: checkCellphone, trigger: 'blur' }
        ],
        disabled: [{ required: true, trigger: 'change' }]
      },
      messages: {
        username: { msg: '用户名已存在,请重新输入', meet: false },
        // nickname: { msg: '姓名已存在,请重新输入', meet: false },
        email: { msg: '邮箱已存在,请重新输入', meet: false },
        cellphone: { msg: '电话号码已存在,请重新输入', meet: false }
      }
    };
  },
  mounted() {},
  methods: {
    initData() {
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields();
        this.disabled = '0';
      });
    },
    // changeInput(v, type) {
    //   // if_repeat({
    //   //   id: '',
    //   //   name: type,
    //   //   value: v,
    //   // }).then((res) => {
    //   //   if (res) {
    //   //     this.$message.warning(this.messages[type].msg)
    //   //   } else {
    //   //     this.messages[type].meet = true
    //   //   }
    //   // })
    // },
    if_repeat(type, v, cb) {
      if_repeat({
        id: '',
        name: type,
        value: v
      }).then(res => {
        cb(res);
      });
    },
    closeFn() {
      this.$emit('closeDialog');
    },
    sureFn() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.if_repeat('username', this.form.username, res1 => {
            if (res1) {
              this.$message.warning(this.messages['username'].msg);
              return;
            } else {
              this.if_repeat('email', this.form.email, res2 => {
                if (res2) {
                  this.$message.warning(this.messages['email'].msg);
                  return;
                } else {
                  this.if_repeat('cellphone', this.form.cellphone, res3 => {
                    if (res3) {
                      this.$message.warning(this.messages['cellphone'].msg);
                      return;
                    } else {
                      this.form.disabled = Number(this.disabled);
                      this.$emit('sureDialog', this.form);
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.inputWidth {
  width: 350px;
  margin-right: 15px;
}
</style>
